import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button } from "reactstrap";
import { useDispatch } from 'react-redux'
import { handleGetDetails } from "../../store/actions/utilAction";
import { Link } from "react-router-dom";
function Home() {
  const dispatch = useDispatch();
  useEffect(()=>{
    dispatch(
      handleGetDetails((args) => {
        console.log("ARGS->", args);
        let newCounterData = [
          {
            id: 0,
            name: "SPECIES",
            count: args?.totalspecies,
            prev: parseInt(args?.totalspecies)-1,
            next: parseInt(args?.totalspecies)+1,
          },
          {
            id: 1,
            name: "CITIZENS SCIENTISTS",
            count: args?.totalcollector,
            prev: parseInt(args?.totalcollector)-10,
            next: parseInt(args?.totalcollector)+10,
          },
          {
            id: 2,
            name: "CONTRIBUTIONS",
            count: args?.totalcontributions,
            prev: parseInt(args?.totalcontributions)-1000,
            next: parseInt(args?.totalcontributions)+1000,
          },
        ]
        setCounterData(newCounterData)
      })
    );
  },[])
  const allCounterData = [
    {
      id: 0,
      name: "SPECIES",
      count: 24,
      prev: 23,
      next: 25,
    },
    {
      id: 1,
      name: "CITIZENS SCIENTISTS",
      count: 270,
      prev: 260,
      next: 280,
    },
    {
      id: 2,
      name: "CONTRIBUTIONS",
      count: 40000,
      prev: 39000,
      next: 410000,
    },
  ];
  const [counterData, setCounterData] = useState(allCounterData);
  const [counterActiveNumber, setCounterActiveNumber] = useState(0);
  const [activeExploreButton, setActiveExploreButton] = useState(-1);
  const [bit, setBit] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      if (counterActiveNumber == 2) setCounterActiveNumber(0);
      else setCounterActiveNumber(counterActiveNumber + 1);
      setBit(!bit);
    }, 2000);
  }, [bit]);

  const changeCounterActiveNumber = (index) => {
    setActiveExploreButton(index);
  };

  // console.log(activeExploreButton)
  const leftSideButtons = [
    {
      name: "nFold",
      id: 1,
      url:"/n-fold",
      desc:'Discover Our Journey: Unfolding Innovation and Excellence',
      style:"home_mid_section_1 activeSection_1"
    },
    {
      name: "Activities",
      id: 2,
      url:'/activities',
      desc:'To keep you informed on upcoming workshops, conferences, and exciting happenings.',
      style:"home_mid_section_1 activeSection_2"
    },
    {
      name: "nFilms",
      id: 3,
      url:'/films',
      desc:'To keep you updated through videos and films about our story and journey behind ncount.',
      style:"home_mid_section_1 activeSection_3"
    },
  ];
  const rightSideButtons = [
    {
      name: "Campaigns",
      id: 4,
      desc:'Dive deep into our initiatives through campaigns worldwide',
      url:'/compaigns',
      style:"home_mid_section_1 activeSection_4"
    },
    {
      name: "nAct",
      id: 5,
      url:"/n-act",
      desc:'Start acting right now! And know more about our different initiative',
      style:"home_mid_section_1 activeSection_5"
    },
    {
      name: "Gap Table",
      id: 6,
      url:'/gap-table',
      desc:'Discussions and Round table for GAP Suggestion process.',
      style:"home_mid_section_1 activeSection_6"
    },
  ];
  return (
    <div>
      <Container>
        <Row className="home_main_section">
          <Col md="1"></Col>
          <Col md="2" className="home_left_section">
            {leftSideButtons.map((button) => {
              return (
                <Button
                  className={
                    activeExploreButton == button.id
                      ? "homeButton activeExploreButton"
                      : "homeButton"
                  }
                  onClick={() => changeCounterActiveNumber(button.id)}
                >
                  {button.name}
                </Button>
              );
            })}
          </Col>
          {
            activeExploreButton==-1 ?
            <Col md="6" className="home_mid_section_container">
            <div className="home_mid_section">
              <div className="home_mid_section_bg">
                <div className="home-mid-section-text-box">
                  <center>
                    <div className="dynamic-counter">
                      <div className="dyanmic-counter-count-prev">
                        <p>{counterData[counterActiveNumber].prev}</p>
                        <p className="counter-title">
                          {counterData[counterActiveNumber].name}
                        </p>
                      </div>
                      <div className="dyanmic-counter-count-current">
                        <p>{counterData[counterActiveNumber].count}</p>
                      </div>
                      <div className="dyanmic-counter-count-next">
                        <p>{counterData[counterActiveNumber].next}</p>
                      </div>
                    </div>
                  </center>
                  <center>
                    <p className="home-mid-section-text">
                      Combining emerging technology and systems approach for
                      people-centric sustainability action at scale
                    </p>
                  </center>
                </div>
                <center>
                  <a href="https://explorer.ncount.in/" target="_blank">
                  <Button className="homeButton">Explore</Button>
                  </a>
                </center>
              </div>
            </div>
          </Col>
          :<Col md="6" className="home_mid_section_container">
          <div className={
                    activeExploreButton >3 
                    ? rightSideButtons[activeExploreButton-4].style
                    : leftSideButtons[activeExploreButton-1].style
                  }>
            <div className="home_mid_section_bg_1">
              <div className="home-mid-section-text-box_1">
                <center>
                  <p className="home-mid-section-text_1">
                  {
                    activeExploreButton >3 
                    ? rightSideButtons[activeExploreButton-4].desc
                    : leftSideButtons[activeExploreButton-1].desc
                  }
                  </p>
                </center>
              </div>
              <center>
                <Link to={activeExploreButton >3 
                    ? rightSideButtons[activeExploreButton-4]?.url
                    : leftSideButtons[activeExploreButton-1]?.url}>
                <Button className="homeButton">Explore</Button>
                </Link>
              </center>
            </div>
          </div>
        </Col>
          }
          <Col md="2" className="home_right_section">
            {rightSideButtons.map((button) => {
              return (
                <Button
                  onClick={() => changeCounterActiveNumber(button.id)}
                  className={
                    activeExploreButton == button.id
                      ? "homeButtonright activeExploreButtonRight"
                      : "homeButtonright"
                  }
                >
                  {button.name}
                </Button>
              );
            })}
          </Col>
          <Col md="1"></Col>
        </Row>
      </Container>
    </div>
  );
}

export default Home;
