import React from 'react'
import { Container,Row,Col } from 'reactstrap'
import instagramIcon from '../../../assets/icons/instagram.png'
import xIcon from '../../../assets/icons/x.png';
import youtubeIcon from '../../../assets/icons/Youtube.png';
import githubIcon from '../../../assets/icons/github.png';
import linkendinIcon from '../../../assets/icons/linkendin.png'; 
import sdgIcon from '../../../assets/icons/sdg.png';
import osIcon from '../../../assets/icons/os.png';

const socialMedia  = [
    {
        name: 'nCount Instagram',
        icon : instagramIcon,
        url:'https://www.instagram.com/ncount_iofe/'
    },
    {
        name: 'nCount Youtube',
        icon : youtubeIcon,
        url:'https://www.youtube.com/@PlaynCount'
    },
    {
        name: 'nCount Github',
        icon : githubIcon,
        url:'https://github.com/Forest-Economy-Alliance'
    },
    {
        name: 'nCount X',
        icon : xIcon,
        url:'https://x.com/ncountforum'
    },
    {
        name: 'nCount Linkendin',
        icon : linkendinIcon,
        url:'https://www.linkedin.com/in/ncount-platform-for-sustainability-action-856679303'
    }
]


function MobFooter() {
  return (
    <div className='footer-container'>
            <Row style={{alignItems:'flex-start'}}>
                <Col xs="4"  className='footer-links-left footer-mob-links-left'>
                  <p>Privacy Policies</p>
                  <p style={{lineHeight:'.9rem'}}>Terms and Conditions</p>
                </Col>
                <Col xs="4" style={{display:'flex',justifyContent:'space-around',alignItems:'center'}}>
                <img style={{width:40}} src={sdgIcon} />
                <img style={{width:32,marginRight:-15}} src={osIcon} />
                </Col>
                <Col xs="4" className='footer-links-right footer-mob-links-right'>
                <p>Contact Us</p>
                  <a style={{textDecoration:'none',color:'white'}} href='https://forum.ncount.in/' target='_blank'><p>Support</p></a>
                </Col>
                </Row>
                <Row className='mt-2' style={{justifyContent:'center'}}>
                <Col md="6" xs="10" style={{display:'flex',alignItems:'center',marginLeft:30}}>
                    {
                        socialMedia.map((media)=>{
                            return(
                             <a className='footer-icon' href={media.url} target='_blank'><img className='footer-icon-img' src={media.icon} alt={media.name}/></a>
                            )
                        })
                    }
                </Col>
            </Row>
    </div>
  )
}

export default MobFooter